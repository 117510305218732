.app {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 160px 200px auto;
  grid-template-rows: 60px auto 60px;
  grid-template-areas:
    "header  header  header"
    "sidebar main    main"
    "footer  footer  footer";
  min-height: 100vh;
  min-width: 100vw;
  text-align: center;
}


