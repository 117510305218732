.app-dashboard{
    background-color: whitesmoke;
    /*    display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;*/
    /*font-size: calc(10px + 2vmin);*/
    min-height: 100%;
    min-width: 100%;
    color: #5C82AD;
}
