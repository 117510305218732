.app-header {
    grid-area: header;
    background-color: whitesmoke;
/*    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;*/
    font-size: calc(10px + 2vmin);
    color: #5C82AD;
}
