.app-login {
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
    font-size: calc(10px + 2vmin);
    color: #5C82AD;
}

.app-login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    min-width: 100%;
}
