.app-sidebar {
    background-color: whitesmoke;
    grid-area: sidebar;
    font-size: calc(10px + 1.5vmin);
    color: #5C82AD;
}

nav{
    background-color: whitesmoke;
    grid-area: sidebar;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    font-size: calc(10px + 1.5vmin);
    color: #5C82AD;
}

.app-link {
    background-color: #282c34;
    color: #61dafb;
    min-width: calc(100% - 2px);
    margin-top: 4px;
    text-decoration: none;
}

.navLinkSelected {
    color: red;
}
